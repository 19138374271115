import { FieldAttributes, useField } from 'formik'
import { Form } from 'react-bootstrap'
import InputErrorWrapper from '../InputErrorWrapper'

// eslint-disable-next-line
const DateInput: React.FC<FieldAttributes<any>> = ({ touched, ...props }): JSX.Element => {
    const [field, meta] = useField(props)

    return (
        <InputErrorWrapper meta={meta} testId={props.name}>
            <Form.Control
                type="date"
                {...props}
                {...field}
                isInvalid={!!meta.error && meta.touched}
                isValid={!meta.error && meta.touched}
                data-testid={`formDateInput_${props.name}`}
            />
        </InputErrorWrapper>
    )
}

export default DateInput
