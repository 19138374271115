import { FieldAttributes, useField } from 'formik'
import { Form } from 'react-bootstrap'
import InputErrorWrapper from '../InputErrorWrapper'

export type selectOptions = {
    value: string
    label: string | number
}

interface CustomInputProps {
    options: selectOptions[]
}

// eslint-disable-next-line
const SelectField: React.FC<CustomInputProps & FieldAttributes<any>> = ({
    // eslint-disable-next-line
    touched,
    options,
    ...props
}): JSX.Element => {
    const [field, meta] = useField(props)

    return (
        <InputErrorWrapper meta={meta} testId={props.name}>
            <Form.Control
                as="select"
                {...field}
                {...props}
                isInvalid={!!meta.error && meta.touched}
                isValid={!meta.error && meta.touched}
                data-testid={`formSelectField_${props.name}`}
            >
                {options.map((option: selectOptions) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </Form.Control>
        </InputErrorWrapper>
    )
}

export default SelectField
