import styles from './H3.module.scss'

type Props = {
    children: React.ReactNode
}
const H3 = (props: Props): JSX.Element => {
    return <h3 className={styles.headline}>{props.children}</h3>
}

export default H3
