import styles from './TextMuted.module.scss'

type Props = {
    children: React.ReactNode
}
const TextMuted = (props: Props): JSX.Element => {
    return <span className={styles.text}>{props.children}</span>
}

export default TextMuted
