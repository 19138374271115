import { PaymentDataType } from './PaymentDataType'

const InitialValuesPaymentData: PaymentDataType = {
    paymentData: {
        iban: '',
        bic: '',
        hasDifferentAccountOwner: false,
        firstName: '',
        lastName: '',
        sepaAccepted: false,
    },
}

export default InitialValuesPaymentData
