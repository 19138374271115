import { Row, Col } from 'react-bootstrap'
import { AdditionalDataType } from './AdditionalData'
import { H2, Checkbox, FormInputLabeled } from 'common'

interface Props {
    values: AdditionalDataType
}

const FormAdditionalData = ({ values }: Props): JSX.Element => {
    return (
        <>
            <H2>Sonstiges</H2>
            <Row className="pb-5">
                <Col>
                    <p className="pb-3 m-0">
                        <strong>Kids in die Clubs</strong>
                    </p>
                    <p className="pb-3 m-0">
                        Der Niendorfer TSV fördert Kinder aus sozial schwächer gestellten Familien. Im „Kids in die
                        Clubs-Programm“ kann der monatliche Grundbeitrag des Vereins für ein Kind unter 18 Jahren über
                        die Hamburger Sportjugend bzw. Sozialbehörde Hamburg unter bestimmten Voraussetzungen übernommen
                        werden. Hierfür sind aktuelle Einkommensnachweise bzw. Leistungsnachweise wie Wohngeld- oder
                        Arbeitslosengeld-Nachweise notwendig. Sprechen Sie uns gerne an! Infos: Tel.{' '}
                        <a href="tel:0405542160">040-554216-0</a> im Sportbüro im ADYTON, Sachsenweg 78, Mo-Fr. 10-18
                        Uhr.
                    </p>
                    <p className="pb-3 m-0">
                        Bitte beachten Sie, dass Ihr Kind vorerst als vollwertiges Mitglied aufgenommen wird.
                    </p>
                </Col>
            </Row>
            <Row className="pb-5">
                <Col>
                    <FormInputLabeled
                        controlId="additionalData.privacy"
                        inputComponent={
                            <Checkbox
                                label="Datenschutz&nbsp;*"
                                name="additionalData.privacy"
                                value={values.additionalData.privacy}
                                className="privacy"
                            />
                        }
                    />
                    <p className="py-3 m-0">
                        Mit der Erhebung, Erfassung, Nutzung und Verarbeitung meiner personenbezogenen Daten für den
                        Zweck der Mitgliedschaft, gemäß Satzung des Vereins, erkläre ich mich einverstanden. Die
                        beigefügten Datenschutzhinweise gemäß DSGVO habe ich gelesen und erkenne diese an.
                    </p>
                    <p className="pb-3 m-0">
                        Die Datenschutzhinweise sind auch auf Anfrage in der Geschäftsstelle erhältlich oder jederzeit
                        auf unserer{' '}
                        <a href="https://www.niendorfer-tsv.de/datenschutz/" target="_blank" rel="noopener noreferrer">
                            Webseite
                        </a>{' '}
                        einsehbar.
                    </p>
                </Col>
            </Row>
            <Row className="pb-4">
                <Col>
                    <FormInputLabeled
                        controlId="additionalData.statute"
                        inputComponent={
                            <Checkbox
                                name="additionalData.statute"
                                value={values.additionalData.statute}
                                className="statute"
                                label="Ich habe die Satzung zur Kenntnis genommen&nbsp;*"
                            />
                        }
                    />{' '}
                    <p>
                        Die beigefügte{' '}
                        <a
                            href="https://www.niendorfer-tsv.de/satzung-und-ordnungen/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Satzung
                        </a>{' '}
                        habe ich gelesen und erkenne diese an.
                    </p>
                </Col>
            </Row>
        </>
    )
}

export default FormAdditionalData
