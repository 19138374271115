import { AdditionalDataType } from './AdditionalData'

const InitialValuesAdditionalData: AdditionalDataType = {
    additionalData: {
        privacy: false,
        statute: false,
    },
}

export default InitialValuesAdditionalData
