import { FieldMetaProps } from 'formik'
import styles from './InputErrorWrapper.module.scss'

type Props = {
    // eslint-disable-next-line
    meta: FieldMetaProps<any>
    testId: string
    children: React.ReactNode
}

const InputErrorWrapper = ({ meta, testId, children }: Props): JSX.Element => {
    return (
        <div className={styles.container}>
            {children}
            {meta.touched && meta.error && (
                <div className={styles.errorMessage} data-testid={`formError_${testId}`}>
                    {meta.error}
                </div>
            )}
        </div>
    )
}

export default InputErrorWrapper
