import { TextMuted } from 'common'
import { Container } from 'react-bootstrap'
import styles from './SuccessMessage.module.scss'

const SuccessMessage = (): JSX.Element => {
    return (
        <Container data-testid="registrationSucceed">
            <h5>
                <TextMuted>Vielen Dank für Ihren Antrag auf Mitgliedschaft im Niendorfer TSV.</TextMuted>
            </h5>
            <div className={styles.message}>
                <TextMuted>
                    Wir haben soeben einen Link an die angegebene E-Mailadresse versandt. Bitte bestätigen Sie diesen
                    innerhalb von 12 Stunden, um den Antrag verbindlich abzuschließen.
                </TextMuted>
            </div>
            <div className={styles.greeting}>
                <TextMuted>Mit sportlichen Grüßen,</TextMuted>
            </div>
            <div>
                <TextMuted>Ihr Niendorfer TSV</TextMuted>
            </div>
        </Container>
    )
}

export default SuccessMessage
