import { Col, Container, Nav, Row } from 'react-bootstrap'
import styles from './PageFooter.module.scss'

import logo from './logo_hamburg_airport.jpg'
import facebook from './icon_facebook.svg'
import instagram from './icon_instagram.svg'
import youtube from './icon_youtube.svg'

const PageFooter = (): JSX.Element => {
    return (
        <footer className={styles.pageFooter}>
            <Container>
                <Row>
                    <Col>
                        <Nav className="flex-column mt-2">
                            <Nav.Item as="li" className="list-unstyled">
                                <Nav.Link
                                    className={[styles.link, 'd-inline-block p-0 mb-3'].join(' ')}
                                    href="https://www.niendorfer-tsv.de/impressum/"
                                    target="_blank"
                                >
                                    Impressum
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="list-unstyled">
                                <Nav.Link
                                    className="d-inline-block p-0 mb-3"
                                    href="https://www.niendorfer-tsv.de/datenschutz/"
                                    target="_blank"
                                >
                                    Datenschutz
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="list-unstyled">
                                <Nav.Link
                                    className="d-inline-block p-0 mb-3"
                                    href="https://www.niendorfer-tsv.de/#"
                                    target="_blank"
                                >
                                    Privatsphäre
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <img className={styles.img} src={logo} alt="Hamburg Airport" />
                    </Col>
                    <Col className="text-right">
                        <a
                            className={styles.socialLink}
                            href="https://www.instagram.com/niendorfertsv1919/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="mr-3" src={instagram} alt="Instagram" />
                        </a>
                        <a
                            className={styles.socialLink}
                            href="https://www.facebook.com/niendorfertsv"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="mr-3" src={facebook} alt="Facebook" />
                        </a>
                        <a
                            className={styles.socialLink}
                            href="https://www.youtube.com/channel/UCebOpAhD7bwWSYq13g8Q4rw"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={youtube} alt="YouTube" />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <div className={styles.copyright}>
                        © 2023 Niendorfer Turn- und Sportverein von 1919 e.V., Hamburg
                    </div>
                </Row>
            </Container>
        </footer>
    )
}

export default PageFooter
