import React, { useState, useRef, useEffect } from 'react'
import { InfoIcon } from 'common'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './FieldInfo.module.scss'

type Props = {
    tipId: string
    trigger?: 'hover' | 'click'
    children: React.ReactNode
}

const renderPopover = ({ tipId, children }: Props) => {
    return (
        <Popover id={`toolTip_${tipId}`}>
            <Popover.Content>{children}</Popover.Content>
        </Popover>
    )
}

const FieldInfo = (props: Props): JSX.Element => {
    const [show, setShow] = useState(false)
    const trigger = props.trigger || 'click' // default to 'click' if not provided
    const ref = useRef<HTMLSpanElement | null>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setShow(false)
        }
    }

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [show])

    const togglePopover = () => setShow(!show)

    return (
        <OverlayTrigger trigger={trigger} show={show} placement="top" overlay={renderPopover(props)}>
            <span
                className={styles.fieldinfo}
                onClick={trigger === 'click' ? togglePopover : undefined}
                onMouseEnter={trigger === 'hover' ? () => setShow(true) : undefined}
                onMouseLeave={trigger === 'hover' ? () => setShow(false) : undefined}
                ref={ref}
            >
                <InfoIcon />
            </span>
        </OverlayTrigger>
    )
}

export default FieldInfo
