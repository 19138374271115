import { FieldAttributes, useField } from 'formik'
import { Form } from 'react-bootstrap'
import InputErrorWrapper from '../InputErrorWrapper'

type RadioButtonOption = {
    label: string
    value: string
}

interface CustomInputProps {
    options: RadioButtonOption[]
}

// eslint-disable-next-line
const RadionButtonGroup: React.FC<CustomInputProps & FieldAttributes<any>> = ({
    options,
    // eslint-disable-next-line
    touched,
    ...props
}): JSX.Element => {
    const [field, meta] = useField(props)

    return (
        <InputErrorWrapper meta={meta} testId={props.name}>
            <Form.Group controlId={props.name} data-testid={`formRadioButtonGroup_${props.name}`}>
                {options.map((option: RadioButtonOption) => {
                    return (
                        <Form.Check
                            type="radio"
                            key={`radioButton_${option.value}`}
                            {...props}
                            {...field}
                            id={`${props.name}_${option.value}`}
                            value={option.value}
                            checked={field.value === option.value}
                            onChange={(event) => {
                                field.onBlur(event)
                                field.onChange(event)
                            }}
                            label={option.label}
                            data-testid={`formRadioButton_${props.name}_${option.value}`}
                        />
                    )
                })}
            </Form.Group>
        </InputErrorWrapper>
    )
}

export default RadionButtonGroup
