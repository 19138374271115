import { FieldAttributes, useField } from 'formik'
import { Form } from 'react-bootstrap'
import { FormControlElement } from 'common/types/forms'
import { friendlyFormatIBAN } from 'ibantools'
import InputErrorWrapper from '../InputErrorWrapper'
import { useState } from 'react'

// eslint-disable-next-line
const IbanInput: React.FC<FieldAttributes<any>> = ({ touched, ...props }): JSX.Element => {
    const [field, meta] = useField(props)
    const [formattedIban, setFormattedIban] = useState<string>('')

    const handleOnChange = (event: React.ChangeEvent<FormControlElement>): void => {
        const ibanInput = event.target.value || ''
        const newIban = friendlyFormatIBAN(ibanInput)

        setFormattedIban(newIban || '')
        field.onChange(event)
    }

    return (
        <InputErrorWrapper meta={meta} testId={props.name}>
            <Form.Control
                {...props}
                {...field}
                onChange={(event) => {
                    handleOnChange(event)
                }}
                value={formattedIban || field.value}
                isInvalid={!!meta.error && meta.touched}
                isValid={!meta.error && meta.touched}
                data-testid={`formIbanInput_${props.name}`}
            />
        </InputErrorWrapper>
    )
}

export default IbanInput
