import { Col, Row } from 'react-bootstrap'
import { DateInput, EmailInput, FormInputLabeled, NumberInput, RadioButtonGroup, SelectField, TextInput } from 'common'
import styles from '../FormSportData/FormSportData.module.scss'
import { SportDataType } from '../FormSportData'

interface Props {
    values: SportDataType
}

const FormParentPersonalData = ({ values }: Props): JSX.Element => {
    return (
        <>
            {/*TODO: tutaj parent-child-form */}
            {values.sportData.childAthletics && (
                <Row className={styles.box}>
                    <Col>
                        <p className="mb-0 pb-3">
                            Bei unseren jüngsten Mitgliedern ist es erforderlich, dass für das Sportangebot
                            Eltern-Kind-Turnen (bis 4 Jahre) eine Begleitperson im Niendorfer TSV erfasst ist. Für die
                            Aufnahme dieser Begleitperson wird keine Aufnahmegebühr berechnet. Diese Mitgliedschaft
                            endet automatisch in dem Quartal, in dem Ihr Kind 4 Jahre alt wird.
                        </p>
                        {/* TODO: remove if necessary {needsAttendee(age) && (*/}
                        <div className="radioGroupWrapper pt-3 pb-4">
                            <FormInputLabeled
                                controlId="sportData.attendee"
                                inputComponent={
                                    <RadioButtonGroup
                                        name="sportData.attendee"
                                        options={[
                                            {
                                                value: 'new',
                                                label: 'Begleitperson ist noch kein Mitglied',
                                            },
                                            {
                                                value: 'member',
                                                label: 'Begleitperson ist bereits Mitglied',
                                            },
                                        ]}
                                        value={values.sportData.attendee}
                                        className="attendee"
                                        data-testid="formRadioButton_sportData.attendee_member"
                                    />
                                }
                            />
                        </div>
                        {/*)}*/}
                        {/* TODO: remove if necessary {needsAttendee(age) && values.sportData.attendee === 'new' && (*/}
                        {values.sportData.attendee === 'new' && (
                            <Row>
                                <Col md={6}>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Vorname&nbsp;*"
                                            controlId="sportData.attendeeNew.firstName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.firstName"
                                                    placeholder="Vorname"
                                                    value={values.sportData.attendeeNew?.firstName}
                                                    className="firstName"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Nachname&nbsp;*"
                                            controlId="sportData.attendeeNew.lastName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.lastName"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeNew?.lastName}
                                                    className="lastName"
                                                />
                                            }
                                        />
                                    </div>

                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Straße&nbsp;*"
                                            controlId="sportData.attendeeNew.street"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.street"
                                                    placeholder="Straße"
                                                    value={values.sportData.attendeeNew?.street}
                                                    className="street"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Hausnummer&nbsp;*"
                                            controlId="sportData.attendeeNew.streetNumber"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.streetNumber"
                                                    placeholder="Hausnummer"
                                                    value={values.sportData.attendeeNew?.streetNumber}
                                                    className="streetNumber"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="PLZ&nbsp;*"
                                            controlId="sportData.attendeeNew.zip"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <NumberInput
                                                    name="sportData.attendeeNew.zip"
                                                    placeholder="PLZ"
                                                    value={values.sportData.attendeeNew?.zip}
                                                    className="zip"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Ort&nbsp;*"
                                            controlId="sportData.attendeeNew.city"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeNew.city"
                                                    placeholder="Ort"
                                                    value={values.sportData.attendeeNew?.city}
                                                    className="city"
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className="pb-3">
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Geburtsdatum&nbsp;*"
                                            controlId="sportData.attendeeNew.dateOfBirth"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <DateInput
                                                    name="sportData.attendeeNew.dateOfBirth"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeNew?.dateOfBirth}
                                                    className="dateOfBirth"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Geschlecht&nbsp;*"
                                            controlId="sportData.attendeeNew.gender"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <SelectField
                                                    name="sportData.attendeeNew.gender"
                                                    placeholder="Geschlecht"
                                                    value={values.sportData.attendeeNew?.gender}
                                                    className="gender"
                                                    options={[
                                                        { value: '', label: 'Bitte wählen' },
                                                        { value: 'weiblich', label: 'Weiblich' },
                                                        { value: 'männlich', label: 'Männlich' },
                                                        { value: 'unbekannt', label: 'Divers' },
                                                    ]}
                                                    data-testid="formDateInput_sportData.attendeeNew.gender"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="E-Mail Adresse&nbsp;*"
                                            controlId="sportData.attendeeNew.email"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <EmailInput
                                                    name="sportData.attendeeNew.email"
                                                    placeholder="example@web.de"
                                                    value={values.sportData.attendeeNew?.email}
                                                    className="email"
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {/* TODO: remove if necessary {needsAttendee(age) && values.sportData.attendee === 'member' && (*/}
                        {values.sportData.attendee === 'member' && (
                            <Row>
                                <Col md={6}>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Mitglieds&shy;nummer&nbsp;*"
                                            controlId="sportData.attendeeMember.memberId"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeMember.memberId"
                                                    placeholder="Mitgliedsnummer"
                                                    value={values.sportData.attendeeMember?.memberId}
                                                    className="memberId"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Vorname&nbsp;*"
                                            controlId="sportData.attendeeMember.firstName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeMember.firstName"
                                                    placeholder="Vorname"
                                                    value={values.sportData.attendeeMember?.firstName}
                                                    className="firstName"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Nachname&nbsp;*"
                                            controlId="sportData.attendeeMember.lastName"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <TextInput
                                                    name="sportData.attendeeMember.lastName"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeMember?.lastName}
                                                    className="lastName"
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Geburtsdatum&nbsp;*"
                                            controlId="sportData.attendeeMember.dateOfBirth"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <DateInput
                                                    name="sportData.attendeeMember.dateOfBirth"
                                                    placeholder="Nachname"
                                                    value={values.sportData.attendeeMember?.dateOfBirth}
                                                    className="dateOfBirth"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="pb-3">
                                        <FormInputLabeled
                                            label="Geschlecht&nbsp;*"
                                            controlId="sportData.attendeeMember.gender"
                                            labelColumnSize={4}
                                            inputComponent={
                                                <SelectField
                                                    name="sportData.attendeeMember.gender"
                                                    placeholder="Geschlecht"
                                                    value={values.sportData.attendeeMember?.gender}
                                                    className="gender"
                                                    options={[
                                                        { value: '', label: 'Bitte wählen' },
                                                        { value: 'weiblich', label: 'Weiblich' },
                                                        { value: 'männlich', label: 'Männlich' },
                                                        { value: 'unbekannt', label: 'Divers' },
                                                    ]}
                                                    data-testid="formDateInput_sportData.attendeeMember.gender"
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}
        </>
    )
}

export default FormParentPersonalData
