const getCalendarYearsRestriction = (lastYears: number): { maxDate: string; minDate: string } => {
    const today = new Date()
    const minDate = new Date(today)
    minDate.setFullYear(today.getFullYear() - lastYears)

    return {
        minDate: minDate.toISOString().split('T')[0], // Format minDate to YYYY-MM-DD
        maxDate: today.toISOString().split('T')[0], // Format maxDate to YYYY-MM-DD
    }
}

export default getCalendarYearsRestriction
