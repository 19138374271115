import { Container, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { H1 } from 'common'
import SuccessMessage from 'components/SuccessMessage'
import useRegisterService from 'hooks/registration/useRegisterService'
import FormikBuilder from 'components/FormikBuilder'
import { RegistrationData } from 'components/FormikBuilder/FormikBuilder'
import styles from './App.module.scss'
import PageHeader from 'shared_components/PageHeader'
import PageFooter from 'shared_components/PageFooter'

function App(): JSX.Element {
    const { service, sendNewMember } = useRegisterService()

    const handleCreate = async (registrationData: RegistrationData): Promise<void> => {
        await sendNewMember(registrationData).catch(() => {
            toast.error(
                <div data-testid="failureToast">Ihr Anmeldung ist fehlgeschlagen, bitte prüfen Sie Ihre Eingaben.</div>,
            )
        })
    }

    const render = (): JSX.Element => {
        if (service.status === 'loaded') {
            return <SuccessMessage />
        }

        return <FormikBuilder onCreate={handleCreate} />
    }

    return (
        <div data-testid="wrapper" className={styles.app}>
            <PageHeader />
            <Container className="pt-3">
                <Row>
                    <Col>
                        <H1>Antragsformular für eine Mitgliedschaft im Niendorfer TSV</H1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className={styles.pageWrapper}>
                    <Row>
                        <Col>{render()}</Col>
                    </Row>
                </div>
            </Container>
            <PageFooter />
        </div>
    )
}

export default App
