import { nameRegEx, phoneRegEx } from 'common/const/regEx'
import determineAge from 'shared_components/determineAge'
import * as Yup from 'yup'

const ValidationSchemaPersonalDataCreate = {
    personalData: Yup.object({
        memberId: Yup.string().optional(),
        membershipType: Yup.string().required(' '),
        title: Yup.string().optional(),
        firstName: Yup.string().required(' ').max(64, ' ').matches(nameRegEx, 'Keine Sonderzeichen'),
        lastName: Yup.string().required(' ').matches(nameRegEx, 'Keine Sonderzeichen'),
        street: Yup.string().required(' '),
        streetNumber: Yup.string().required(' '),
        zip: Yup.number()
            .test('length', '5 stellige PLZ ', (value) => value?.toString().length === 5)
            .required(),
        city: Yup.string().required(' '),
        dateOfBirth: Yup.string().required(' '),
        gender: Yup.string().required(' '),
        phone: Yup.string().matches(phoneRegEx, ' ').required(' '),
        email: Yup.string().email().required('Keine gültige E-Mail-Adresse'),
        co: Yup.string().when(['dateOfBirth'], {
            is: (dateOfBirth: string) => {
                const age = determineAge(new Date(), dateOfBirth)
                return age < 18
            },
            then: Yup.string().required('Pflicht bei Minderjährigen'),
        }),
    }),
}
export default ValidationSchemaPersonalDataCreate

export const ValidationSchemaPersonalDataUpdate = {
    personalData: Yup.object({
        memberId: Yup.string().optional(),
        title: Yup.string().optional(),
        firstName: Yup.string().required(' ').max(64, ' ').matches(nameRegEx, 'Keine Sonderzeichen'),
        lastName: Yup.string().required(' ').matches(nameRegEx, 'Keine Sonderzeichen'),
        street: Yup.string().required(' '),
        streetNumber: Yup.string().required(' '),
        zip: Yup.number()
            .test('length', '5 stellige PLZ ', (value) => value?.toString().length === 5)
            .required(),
        city: Yup.string().required(' '),
        dateOfBirth: Yup.string().required(' '),
        gender: Yup.string().required(' '),
        phone: Yup.string().matches(phoneRegEx, ' ').required(' '),
        email: Yup.string().email().required('Keine gültige E-Mail-Adresse'),
        co: Yup.string().when(['dateOfBirth'], {
            is: (dateOfBirth: string) => {
                const age = determineAge(new Date(), dateOfBirth)
                return age < 18
            },
            then: Yup.string().required('Pflicht bei Minderjährigen'),
        }),
    }),
}
