import React from 'react'

const TanzportTooltip = (): JSX.Element => (
    <p>
        <b>Bitte beachten:</b> dieses Angebot ist Teil der Sparte TFG, neben dem regulären Spartenbeitrag der TFG fällt
        der separate Zusatzbeitrag für dieses Angebot an. Die genauen Beiträge sind hier{' '}
        <a href="https://www.niendorfer-tsv.de/beitraege/" target="_blank" rel="noreferrer">
            https://www.niendorfer-tsv.de/beitraege/
        </a>{' '}
        zu finden.
    </p>
)

export default TanzportTooltip
