import { FieldAttributes, useField } from 'formik'
import { Form } from 'react-bootstrap'
import InputErrorWrapper from '../InputErrorWrapper'

interface CustomInputProps {
    label: string
}

// eslint-disable-next-line
const Checkbox: React.FC<CustomInputProps & FieldAttributes<any>> = ({
    label,
    // eslint-disable-next-line
    touched,
    controlId,
    ...props
}): JSX.Element => {
    const [field, meta] = useField(props)
    const actualControlId = controlId || props.name

    return (
        <InputErrorWrapper meta={meta} testId={props.name}>
            <Form.Group controlId={actualControlId}>
                <Form.Check
                    label={label}
                    {...field}
                    {...props}
                    isInvalid={!!meta.error && meta.touched}
                    onChange={(event) => {
                        field.onBlur(event)
                        field.onChange(event)
                    }}
                    checked={field.value}
                    data-testid={`formCheckbox_${props.name}`}
                />
            </Form.Group>
        </InputErrorWrapper>
    )
}

export default Checkbox
