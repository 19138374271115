import { FieldAttributes, useField } from 'formik'
import { Form } from 'react-bootstrap'
import InputErrorWrapper from '../InputErrorWrapper'

// eslint-disable-next-line
const TextInput: React.FC<FieldAttributes<any>> = ({ touched, isValid = true, ...props }): JSX.Element => {
    const [field, meta] = useField(props)

    return (
        <InputErrorWrapper meta={meta} testId={props.name}>
            <Form.Control
                {...field}
                {...props}
                value={field.value || ''}
                isInvalid={(!!meta.error && meta.touched) || !isValid}
                isValid={!meta.error && meta.touched}
                data-testid={`formTextInput_${props.name}`}
            />
        </InputErrorWrapper>
    )
}

export default TextInput
