import { Col, Row } from 'react-bootstrap'
import { PaymentDataType } from './PaymentDataType'
import { H2, FormInputLabeled, TextInput, IbanInput, Checkbox } from 'common'

interface Props {
    values: PaymentDataType
}

const FormPaymentData = ({ values }: Props): JSX.Element => {
    return (
        <>
            <H2>Ihre Zahlungsangaben</H2>
            <Row className="pb-4">
                <Col lg={6}>
                    <FormInputLabeled
                        label="IBAN&nbsp;*"
                        controlId="paymentData.iban"
                        labelColumnSize={4}
                        inputComponent={
                            <IbanInput
                                name="paymentData.iban"
                                placeholder="DE01"
                                value={values.paymentData.iban}
                                className="paymentData.iban"
                            />
                        }
                    />
                </Col>
                <Col lg={6}>
                    <FormInputLabeled
                        label="BIC"
                        controlId="paymentData.bic"
                        labelColumnSize={4}
                        inputComponent={
                            <TextInput
                                name="paymentData.bic"
                                placeholder="BICXXXX"
                                value={values.paymentData.bic}
                                className="paymentData.bic"
                            />
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col className="pb-3">
                    <FormInputLabeled
                        controlId="paymentData.hasDifferentAccountOwner"
                        inputComponent={
                            <Checkbox
                                label="Kontoinhaber ist nicht Antragsteller"
                                name="paymentData.hasDifferentAccountOwner"
                                value={values.paymentData.hasDifferentAccountOwner}
                                className="paymentData.hasDifferentAccountOwner"
                            />
                        }
                    />
                    {values.paymentData.hasDifferentAccountOwner && (
                        <Row className="py-3">
                            <Col lg={6}>
                                <FormInputLabeled
                                    label="Vorname&nbsp;*"
                                    controlId="paymentData.firstName"
                                    labelColumnSize={4}
                                    inputComponent={
                                        <TextInput
                                            label="Vorname"
                                            name="paymentData.firstName"
                                            placeholder="Vorname"
                                            value={values.paymentData.firstName}
                                            className="paymentData.firstName"
                                        />
                                    }
                                />
                            </Col>
                            <Col lg={6}>
                                <FormInputLabeled
                                    label="Nachname&nbsp;*"
                                    controlId="paymentData.lastName"
                                    labelColumnSize={4}
                                    inputComponent={
                                        <TextInput
                                            label="Nachname"
                                            name="paymentData.lastName"
                                            placeholder="Nachname"
                                            value={values.paymentData.lastName}
                                            className="paymentData.lastName"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInputLabeled
                        controlId="paymentData.sepaAccepted"
                        inputComponent={
                            <Checkbox
                                label="SEPA-Lastschrift&nbsp;*"
                                name="paymentData.sepaAccepted"
                                value={values.paymentData.sepaAccepted}
                                className="paymentData.sepaAccepted"
                            />
                        }
                    />
                    <p className="py-3 m-0">
                        Laut Satzung werden Beiträge und Gebühren per Lastschrift vom Konto des Mitglieds eingezogen.
                        Gläubiger-ID: DE89ZZZ00000267639 Mandatsnummer: wird Ihnen mit der Eintrittsbestätigung
                        mitgeteilt.
                    </p>
                    <p className="p-0 m-0">
                        Hiermit ermächtige/n ich/wir den Niendorfer TSV, die Beiträge und Gebühren für das oben
                        angeführte Mitglied zu Lasten meines/unseres unten angegebenen Girokontos mittels Lastschrift
                        einzuziehen. Zugleich weise/n ich/wir mein/unser Kreditinstitut an, die vom Niendorfer TSV auf
                        mein/unser Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht
                        Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es
                        gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Beiträge werden
                        grundsätzlich am 10. Bankarbeitstag monatlich/vierteljährlich im Voraus eingezogen.
                    </p>
                </Col>
            </Row>
        </>
    )
}

export default FormPaymentData
