import React from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ToastContainer } from 'react-toastify'
import { ErrorFallback } from 'common'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.scss'

const render = async (Component: React.FunctionComponent): Promise<void> => {
    if (process.env.MSW === 'on') {
        // eslint-disable-next-line
        const { worker } = require('./common/tests/server/browser')
        await worker.start()
    }

    ReactDOM.render(
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Component />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                limit={5}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </ErrorBoundary>,
        document.getElementById('root'),
    )
}

render(App)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
