import * as Yup from 'yup'

const ValidationSchemaAdditionalData = {
    additionalData: Yup.object({
        privacy: Yup.boolean().isTrue(' '),
        statute: Yup.boolean().isTrue(' '),
    }),
}

export default ValidationSchemaAdditionalData
