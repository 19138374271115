import { Col, Row } from 'react-bootstrap'
import { PersonalDataType } from './PersonalData'

import { DateInput, EmailInput, FormInputLabeled, H2, NumberInput, SelectField, TextInput } from 'common'
import FieldInfo from 'shared_components/FieldInfo'
import { SportDataType } from '../FormSportData'
import getCalendarYearsRestriction from '../helpers/getCalendarYearsRestriction/getCalendarYearsRestriction'

interface Props {
    values: PersonalDataType & SportDataType
    mode?: 'create' | 'update'
}

const IS_UNDER_4 = 4
const IS_UNDER_17 = 17

const FormPersonalData = ({ values, mode }: Props): JSX.Element => {
    const formMode = !mode ? 'create' : mode

    const calendarRestriction = () => {
        if (values.sportData?.childAthletics) {
            return getCalendarYearsRestriction(IS_UNDER_4)
        }

        if (values.sportData?.trampoline || values.sportData?.sportgymnastik) {
            return getCalendarYearsRestriction(IS_UNDER_17)
        }
        return {
            minDate: null,
            maxDate: new Date().toISOString().split('T')[0],
        }
    }

    return (
        <>
            <H2 className={values.personalData.membershipType === 'passive' ? 'mt-0' : ''}>Ihre persönlichen Daten</H2>
            <Row className="pb-4">
                <Col lg={6}>
                    <div className="pb-4">
                        <FormInputLabeled
                            label="Titel"
                            controlId="personalData.title"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.title"
                                    placeholder="Titel"
                                    value={values.personalData.title}
                                    className="title"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Vorname&nbsp;*"
                            controlId="personalData.firstName"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.firstName"
                                    placeholder="Vorname"
                                    value={values.personalData.firstName}
                                    className="firstName"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Nachname&nbsp;*"
                            controlId="personalData.lastName"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.lastName"
                                    placeholder="Name"
                                    value={values.personalData.lastName}
                                    className="lastName"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Straße&nbsp;*"
                            controlId="personalData.street"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.street"
                                    placeholder="Straße"
                                    value={values.personalData.street}
                                    className="street"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="HausNr.&nbsp;*"
                            controlId="personalData.streetNumber"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.streetNumber"
                                    placeholder="HausNr."
                                    value={values.personalData.streetNumber}
                                    className="streetNumber"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="PLZ&nbsp;*"
                            controlId="personalData.zip"
                            labelColumnSize={4}
                            inputComponent={
                                <NumberInput
                                    name="personalData.zip"
                                    placeholder="PLZ"
                                    value={values.personalData.zip}
                                    className="zip"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Ort&nbsp;*"
                            controlId="personalData.city"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.city"
                                    placeholder="Ort"
                                    value={values.personalData.city}
                                    className="city"
                                />
                            }
                        />
                    </div>
                </Col>
                <Col lg={6}>
                    {formMode === 'create' && (
                        <div className="pb-4">
                            <FormInputLabeled
                                label={
                                    <>
                                        Familien&shy;mitglieds&shy;nummer
                                        <FieldInfo tipId="memberId">
                                            <div>
                                                <p>
                                                    Bei einer bestehenden Mitgliedschaft, bitte diese Nummer angeben.
                                                    Bei Neuanmeldungen einer Familienmitgliedschaft führen Sie bitte
                                                    zunächst eine Einzelanmeldung durch. Mit der erhaltenen
                                                    Mitgliedsnummer können Sie weitere Familienmitglieder hinzufügen.
                                                    Bitte <strong>OHNE</strong> laufende Nummer und Punkt eingeben
                                                    <strong>12345</strong>.6.
                                                </p>
                                            </div>
                                        </FieldInfo>
                                    </>
                                }
                                controlId="personalData.memberId"
                                labelColumnSize={4}
                                inputComponent={
                                    <TextInput
                                        name="personalData.memberId"
                                        placeholder="Nummer vor dem Punkt - siehe Info"
                                        value={values.personalData.memberId}
                                        className="memberId"
                                        maxLength="5"
                                        isValid={
                                            /^\d+$/.test(values.personalData.memberId) ||
                                            values.personalData.memberId.length === 0
                                        }
                                    />
                                }
                            />
                        </div>
                    )}

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Geburtsdatum&nbsp;*"
                            controlId="personalData.dateOfBirth"
                            labelColumnSize={4}
                            inputComponent={
                                <DateInput
                                    name="personalData.dateOfBirth"
                                    placeholder="Geburtsdatum"
                                    value={values.personalData.dateOfBirth}
                                    className="dateOfBirth"
                                    // TODO: tutaj date restriction
                                    min={calendarRestriction().minDate}
                                    max={calendarRestriction().maxDate}
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Geschlecht&nbsp;*"
                            controlId="personalData.gender"
                            labelColumnSize={4}
                            inputComponent={
                                <SelectField
                                    name="personalData.gender"
                                    placeholder="Geschlecht"
                                    value={values.personalData.gender}
                                    className="gender"
                                    options={[
                                        { value: '', label: 'Bitte wählen' },
                                        { value: 'weiblich', label: 'Weiblich' },
                                        { value: 'männlich', label: 'Männlich' },
                                        { value: 'unbekannt', label: 'Divers' },
                                    ]}
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Telefon&nbsp;*"
                            controlId="personalData.phone"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    type="tel"
                                    name="personalData.phone"
                                    placeholder="04012345678"
                                    value={values.personalData.phone}
                                    className="phone"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="E-Mail-Adresse&nbsp;*"
                            controlId="personalData.email"
                            labelColumnSize={4}
                            inputComponent={
                                <EmailInput
                                    type="email"
                                    name="personalData.email"
                                    placeholder="hello@world"
                                    value={values.personalData.email}
                                    className="email"
                                />
                            }
                        />
                    </div>

                    <div className="pb-4">
                        <FormInputLabeled
                            label="Erziehungs&shy;berechtigte Person"
                            controlId="personalData.co"
                            labelColumnSize={4}
                            inputComponent={
                                <TextInput
                                    name="personalData.co"
                                    placeholder="Maren Muster"
                                    value={values.personalData.co}
                                    className="co"
                                />
                            }
                        />
                        <small>(Pflicht bei Minderjährigen)</small>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default FormPersonalData
