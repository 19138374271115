import { Nav, Navbar } from 'react-bootstrap'
import logo from './Wappen-NTSV-4c-Blende.svg'
import styles from './PageHeader.module.scss'

const PageHeader = (): JSX.Element => {
    return (
        <header className={[styles.pageHeader, 'mb-4'].join(' ')}>
            <img className={styles.logo} src={logo} alt="logo" />
            <Navbar className={[styles.navbar, 'justify-content-center p-0'].join(' ')}>
                <Nav className="justify-content-center p-3">
                    <Nav.Link href="https://www.niendorfer-tsv.de/" className="p-0 mr-5" target="_blank">
                        Startseite
                    </Nav.Link>
                    <Nav.Link href="https://www.niendorfer-tsv.de/kontakt" className="p-0 mr-0" target="_blank">
                        Kontakt
                    </Nav.Link>
                </Nav>
            </Navbar>
        </header>
    )
}

export default PageHeader
