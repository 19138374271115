import styles from './H2.module.scss'

type Props = {
    children: React.ReactNode
    className?: string
}
const H2 = (props: Props): JSX.Element => {
    return <h2 className={[styles.headline, props?.className || ''].join(' ')}>{props.children}</h2>
}

export default H2
