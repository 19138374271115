import { isValid, parse, differenceInYears } from 'date-fns'

export default (today: Date, dateOfBirthValue?: string): number => {
    if (!dateOfBirthValue) {
        return 18
    }

    const dateOfBirth = parse(dateOfBirthValue, 'yyyy-MM-dd', new Date())

    if (!isValid(dateOfBirth)) {
        return 18
    }

    return differenceInYears(today, new Date(dateOfBirthValue))
}
