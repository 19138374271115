import { PersonalDataType } from './PersonalData'

const InitialValuesPersonalData: PersonalDataType = {
    personalData: {
        memberId: '',
        membershipType: 'active',
        title: '',
        lastName: '',
        firstName: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        dateOfBirth: '',
        gender: '',
        phone: '',
        email: '',
        co: '',
        cardURL: '',
    },
}

export default InitialValuesPersonalData
