import { RegistrationData } from 'components/FormikBuilder/FormikBuilder'
import { useState } from 'react'
import { Service } from 'common'
import transformToXml from './transformToXml'

const apiUrl = process.env.REACT_APP_API_URL

type Hook = {
    service: Service<null>
    sendNewMember: (registrationData: RegistrationData) => Promise<null>
}

function handleErrors(response: Response): Response {
    if (!response.ok) {
        throw Error(response.statusText)
    }

    return response
}

const useRegisterService = (): Hook => {
    const [service, setService] = useState<Service<null>>({
        status: 'init',
    })

    const sendNewMember = (registrationData: RegistrationData): Promise<null> => {
        setService({ status: 'loading' })

        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(registrationData)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    if (response === 'OK') {
                        setService({ status: 'loaded', payload: null })
                        resolve(null)
                    } else {
                        const error = Error('Anfrage wurde abgewiesen')
                        setService({ status: 'error', error })
                        reject(error)
                    }
                })
                .catch((error) => {
                    setService({ status: 'error', error })
                    reject(error)
                })
        })
    }

    return {
        service,
        sendNewMember,
    }
}

export default useRegisterService
