import { MembershipDataType } from './MembershipData'
import { Col, Row } from 'react-bootstrap'
import { FormInputLabeled, RadioButtonGroup } from 'common'

interface Props {
    values: MembershipDataType
    mode?: 'create' | 'update'
}

const FormMembershipData = ({ values, mode = 'create' }: Props): JSX.Element => {
    const formMode = !mode ? 'create' : mode

    if (formMode !== 'create') return <div />

    return (
        <Row className="pb-4 mt-5">
            <Col>
                <FormInputLabeled
                    controlId="personalData.membershipType"
                    label="Mitgliedschaft *"
                    xs={4}
                    lg={2}
                    align="start"
                    inputComponent={
                        <RadioButtonGroup
                            name="personalData.membershipType"
                            options={[
                                { label: 'aktives Mitglied', value: 'active' },
                                { label: 'passives Mitglied (Fördermitglied)', value: 'passive' },
                            ]}
                            value={values.personalData.membershipType}
                            className="membershipType"
                            inline={true}
                        />
                    }
                />
            </Col>
        </Row>
    )
}

export default FormMembershipData
