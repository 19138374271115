import { Button, Spinner } from 'react-bootstrap'

export interface Props {
    children: React.ReactNode
    disabled: boolean
    isSubmitting: boolean
    testId?: string
}

const SubmitButton = ({ children, disabled, isSubmitting, testId = '' }: Props): JSX.Element => {
    return (
        <Button
            variant="primary"
            className="rounded-0"
            disabled={disabled}
            data-testid={`${testId}_submitButton`}
            type="submit"
        >
            <>
                {isSubmitting && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        data-testid={`${testId}_submitSpinner`}
                    />
                )}
                {children}
            </>
        </Button>
    )
}

export default SubmitButton
