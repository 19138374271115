import styles from './H1.module.scss'

type Props = {
    children: React.ReactNode
    className?: string
}
const H1 = (props: Props): JSX.Element => {
    return <h1 className={[styles.headline, 'px-5 m-0', props.className].join(' ')}>{props.children}</h1>
}

export default H1
