import { nameRegEx } from 'common/const/regEx'
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools'
import * as Yup from 'yup'

const validateIban = (iban: string | undefined): boolean => {
    if (!iban) {
        return true
    }

    const ibanWithoutSpaces = electronicFormatIBAN(iban)

    return isValidIBAN(ibanWithoutSpaces || '')
}

const validateBic = (bic: string | undefined): boolean => {
    if (!bic) {
        return true
    }

    return isValidBIC(bic)
}

const ValidationSchemaPaymentData = {
    paymentData: Yup.object({
        iban: Yup.string()
            .test('iban', 'Ungültige IBAN', (value) => validateIban(value?.toString()))
            .required(' '),
        bic: Yup.string().test('bic', 'Ungültige BIC', (value) => validateBic(value?.toString())),
        hasDifferentAccountOwner: Yup.boolean(),
        firstName: Yup.string().when('hasDifferentAccountOwner', {
            is: true,
            then: Yup.string().required(' ').matches(nameRegEx),
        }),
        lastName: Yup.string().when('hasDifferentAccountOwner', {
            is: true,
            then: Yup.string().required(' ').matches(nameRegEx),
        }),
        sepaAccepted: Yup.boolean().isTrue(' ').required(' '),
    }),
}

export default ValidationSchemaPaymentData
